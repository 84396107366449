










































import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import PlusPlan from '@/assets/icons/PlusPlan.vue';
import CheckPlanIcon from '@/assets/icons/checkPlan.vue';
import NotIncluded from '@/assets/icons/NotIncluded.vue';

import DescriptionsLoading from './DescriptionsLoading.vue';

import { Description } from './interface';

import PaymentService from '@/services/Payment/PaymentService';

@Component({
  components: {
    BoxContainer,
    FeedbackUser,
    PlusPlan,
    CheckPlanIcon,
    NotIncluded,
    DescriptionsLoading
  }
})
export default class Descriptions extends Vue {
  @Prop() planId!: number;

  private isLoading = false;

  private descriptions: Description[] = [];

  private PaymentService = new PaymentService();

  get columnOneDescriptions() {
    return this.descriptions.slice(0, Math.ceil(this.descriptions.length / 2));
  }

  get columnTwoDescriptions() {
    return this.descriptions.slice(Math.ceil(this.descriptions.length / 2));
  }

  @Watch('planId', {
    immediate: true
  })
  async getDescriptions() {
    try {
      this.isLoading = true;
      this.descriptions = [];

      if (this.planId !== null) {
        const descriptions = await this.PaymentService.getDescriptions(this.planId);

        if (descriptions && descriptions.length) this.descriptions = descriptions;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
}
